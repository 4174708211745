import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/sale-banner.jpg`;
const indyBanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/INDY-banner.jpg`;


const SpecialOffersSpotifyVideo = () => {
  const getFirstCard = () => {
    const today = new Date();
    const novPromotions =
      today >= new Date(2023,10,1) &&
      today < new Date(2024,0,1);
    if (novPromotions) {
      return (
        <>
          <FlexCard
            rowType="row"
            image={indyBanner}
            imageAlt="SLEIGH RIDE SLIDE"
            header="SLEIGH RIDE SLIDE"
            subheader="GET 20% OFF A GIFT CARD"
            text="Santa says Go, Go, Go this year. Get 20% off a gift card, good for most BMW Performance Driving School classes."
            btnText="Call 888-345-4269 to book now"
            btnStyle="btn-blue-offers"
            phone="tel:888-345-4269"
          >
            <p>
              Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today. Use promo code <span className="bold">23HOLIDAYBMWGIFT</span>.  Must purchase gift card by 12/31/23. M4 GT4 Experience, Race License School, and Private Instruction excluded. Valid in California, South Carolina and Indianapolis locations.
            </p>
          </FlexCard>
          <br />
        </>
      );
    } else {
    return <></>;
  }
};
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage giftcardpageretargeting">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {getFirstCard()}
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersSpotifyVideo;
